<template>

    <div class="row">

        <div class="col-12 col-md-12 note-head">

            <div class="note-title">
                <h4>{{ note.name }}</h4>
            </div>

            <div class="note-btn" v-if="!note.isHideAddBtn">
                <button @click="onOpenModal(note, index)" type="button" class="btn btn-outline-primary btn-sm btn-block">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <hr>

        <div class="col-12 col-md-12 note-body">
            <div class="card">

                <div class="card-body" v-if="!isPhc">

                    <div class="medicine" v-for="(item, noteIndex) in note.selectedNotes" :key="noteIndex">
                        <div class="medicine-name" v-if="note.value === 'investigation_history'">
                            <span class="sr-no">{{ noteIndex + 1 }}. </span>
                            <span class="name">{{ item.name }}</span>
                            <span>
                                <input
                                    v-model.trim="item.result" type="text"
                                    class="note-input-investigation-history form-control"
                                    ref="input"
                                >
                            </span>
                        </div>
                        <div class="medicine-name" v-else>
                            <span class="sr-no">{{ noteIndex + 1 }}. </span>
                            <span v-if="currentIndex !== noteIndex" @click="setEdit(item, noteIndex)" class="name">{{ item }}</span>
                            <span v-show="currentIndex === noteIndex">
                                <input 
                                    v-model.trim="editValue" type="text" 
                                    @blur="onUpdate(item, noteIndex)" 
                                    class="note-input form-control"
                                    ref="input"
                                >
                            </span>
                        </div>
                        <div @click="onRemoveNote(item, noteIndex)" class="action" v-if="!note.isHideAddBtn">
                            <i class="far fa-times-circle close"></i>
                        </div>
                    </div>

                </div>

                <div class="card-body" v-if="isPhc">

                    <div class="medicine">
                        <div class="medicine-name">
                            {{ note.phcNoteText }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
    note: Object,
    index: {
        type: Number,
        default: -1,
    },
    openModal: Function,
    isPhc:{
        type: Boolean,
        default: false,
    }
})

const currentIndex = ref(-1);
const editValue = ref(null);
const input = ref(null)
const emit = defineEmits(['openModal'])

const onOpenModal = (note, index = -1) => {
    emit('openModal', {note, index})
}

const onRemoveNote = (noteName, selectedIndex) => {
    props.note.selectedNotes.splice(selectedIndex, 1)
    const index = props.note.newNotesByDoctor.indexOf(noteName);
    if(index < 0) return;
    props.note.newNotesByDoctor.splice(index);
}

const setEdit = (note, index) => {
    if(props.note.isHideAddBtn || props.note.value === 'investigation_history') return;

    currentIndex.value = index;
    editValue.value = note;
    if (input.value && input.value[index]) {
        input.value[index].focus();
    }
}

const onUpdate = (item, index) => {
    currentIndex.value = -1;
    props.note.selectedNotes.splice(index, 1, editValue.value)
}

</script>

<style scoped>
.note-input{
    position: absolute;
    width: 78%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 3%;
}

.note-input-investigation-history{
    position: absolute;
    width: 43%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 52%;
}

.note-body .card-body{
    padding: 0 2%;
}
.note-head {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 1%;
}
.action .close {
    color: red;
    cursor: pointer;
}

.note-body .card {
    background: #f9f9f9;
    min-height: 100px;
    margin-bottom: 1rem;
}

.medicine {
    display: flex;
    justify-content: space-between;
    margin: 3% 0;
    align-items: center;
    position: relative;
}

</style>